 /* eslint-disable */
import { createApp } from "vue";
import { createPinia } from "pinia"; // Import
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router/router";
import VueSplide from "@splidejs/vue-splide";
import defaultImage from "@/assets/images/defaultImage.jpg";
import VueDatePicker from "@vuepic/vue-datepicker";
import Pagination from "v-pagination-3";
import "@vuepic/vue-datepicker/dist/main.css";
import { createI18n } from "vue-i18n";
// import { Language } from "./stores/Language";
import locales from "./locales/locales";
import axios from "axios";
import { createModal } from "@kolirt/vue-modal";
import vueClickOutsideElement from "vue-click-outside-element";
import VueObserveVisibility from 'vue3-observe-visibility';

// import { socket } from "./socket";
// import { CommonData } from "./stores/Common";

import { useInitializeStore } from "./stores/initialize";
import { useLanguagesStore } from './stores/languages';
import { useCustomerStore } from './stores/customer';
import { useMarketsStore } from './stores/markets';
import { useSocketStore } from "./stores/socket";
import { useFavouritesStore } from "./stores/favourites";
import { useBannersStore } from "./stores/banners";
import { useVirtualCourtStore } from "./stores/virtual_court";
import { MyBets } from "./stores/MyBets";
import { BetSlip } from './stores/BetSlip';
import { useMenuStore } from './stores/menus';

import { openModal } from '@kolirt/vue-modal';
import SetLimitsModal from '@/components/modals/PlayerProtection/SetLimitsModal.vue';
import NotificationsModal from '@/components/modals/NotificationsModal.vue';
import Popper from "vue3-popper";

// Import the worker file
import Worker from './timer_worker.worker.js';
const worker = new Worker();

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(VueObserveVisibility)
app.use(pinia);

// Vue Modal
app.use(
  createModal({
	transitionTime: 200,
	animationType: "slideDown",
	modalStyle: {
	  align: "center",
	  width: "100vw",
	  height: "100vh",
	  "max-width": "100vw",
	  "z-index": 100000,
	},
	overlayStyle: {
	  "background-color": "rgba(0, 0, 0, .5)",
	  "z-index": 2000,
	},
  })
);

const initializeStore = useInitializeStore();
const virtualCourtStore = useVirtualCourtStore();
const menuStore = useMenuStore();

const socketData = () => {

	socketStore.getSocket.on('disconnect', () => {
		console.log('Socket disconnected.');
		location.reload();
	});

	/**
	 * Server timestamp clock tick
	*/
	socketStore.getSocket.on('server_timestamp', (data) => {
		initializeStore.setServerTimestamp(data);
		worker.postMessage({ initialTimestamp: data });
	});

	worker.onmessage = (event) => {
		initializeStore.setServerTimestamp(event.data);

		if (initializeStore.betcomCourtEnabled) {
			if (virtualCourtStore.auth.update_at_timestamp) {
				if (virtualCourtStore.auth.update_at_timestamp <= initializeStore.server_timestamp) {
					virtualCourtStore.refreshTokenVirtualCourt();
				}
			}
		}

	};

	socketStore.getSocket.on('productSettingsChanged', (data) => {
		initializeStore.setProductSettings(data);

		let device_type = menuStore.isMobile ? 'mobile' : 'desktop';
		menuStore.fetchMenus(device_type)
		router.push({'name': 'landing'});
	});

	// /**
	//  * Server timestamp clock tick
	// */
	// socketStore.getSocket.on('server_timestamp', (data) => {
	// 	initializeStore.setServerTimestamp(data);
	// });

	// setInterval(() => {
	// 	initializeStore.tickServerTimestamp();
	// }, 1000);


	/**
	 * Favourites
	*/
	socketStore.getSocket.on('favouriteFixture', (data) => {
		favouritesStore.setFavouriteFixture(data);
	});

	socketStore.getSocket.on('favouriteFixtureUpdate', (data) => {
		favouritesStore.updateFavouriteFixture(data)
	});

	socketStore.getSocket.on('favouriteFixtureDelete', (data) => {
		favouritesStore.deleteFavouriteFixture(data)
	});
}

window.global = window;

global.axios = axios;
global.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// global.axios.defaults.withCredentials = true;

// Add a response interceptor
global.axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {

	if (error.response) {
		if (error.response.status == 401) {
			customerStore.emptyCustomer();
			MyBets().emptyMyBets();
			BetSlip().resetBetslip();
			window.location.href = '/';
		}
	}

	return Promise.reject(error);
});


const mixins = {
	methods: {
		getImage(image_url) {
			if (!image_url) {
				return defaultImage;
			}
			return image_url;
		},
	},
};


const languagesStore = useLanguagesStore();
const marketsStore = useMarketsStore();
const customerStore = useCustomerStore();
const socketStore = useSocketStore();
const favouritesStore = useFavouritesStore();
const bannersStore = useBannersStore();

initializeStore.initialize().then(async () => {

	await bannersStore.fetchHeroBanners();

	if (customerStore.auth_token) {
		global.axios.defaults.headers['Authorization'] = 'Bearer ' + customerStore.auth_token;
		await customerStore.fetchCustomer().then(() => {
			if (customerStore.customer && !customerStore.hasCustomerProtectionLimits) {
				openModal(SetLimitsModal).catch(() => {});
			}
			if (customerStore.customer && customerStore.customerLastNotification) {
				openModal(NotificationsModal, { title: customerStore.customerLastNotification.title, body: customerStore.customerLastNotification.body, notification: customerStore.customerLastNotification }).catch(() => {});
			}
		});
	}

	const locale = languagesStore.selectedLanguage.locale;
	global.axios.defaults.headers.common['Accept-Language'] = locale;
	global.axios.defaults.headers.common['Api-Language'] = locale;

	document.documentElement.setAttribute('lang', locale);
	if (locale == 'fa') document.documentElement.setAttribute('dir', 'rtl');
	else document.documentElement.setAttribute('dir', 'ltr');

	if (locale) {
		zE('webWidget', 'setLocale', locale);
	}

	if (customerStore.isLoggedIn) {

		zE('webWidget', 'prefill', {
			name: {
				value: customerStore.getCustomer.firstname,
				readOnly: true // optional
			},
			phone: {
				value: customerStore.getCustomer.mobile_number,
				readOnly: true // optional
			}
		});
	}

	marketsStore.fetchOverviewMarkets().then(async () => {
		const device_type = menuStore.isMobile ? 'mobile' : 'desktop';
		await menuStore.fetchMenus(device_type);

		socketStore.initializeSocket(locale);
		socketData();

		favouritesStore.joinFavourites();

		const i18n = createI18n({
			locale: locale,
			messages: locales,
			legacy: false,
			globalInjection: true,
		});

		app.mixin(mixins);
		app.use(i18n);
		app.use(router);
		app.use(VueSplide);
		app.use(vueClickOutsideElement);
		app.component("VueDatePicker", VueDatePicker);
		app.component('Popper', Popper);
		app.component("PaginaTion", Pagination);
		app.mount("#app");
	})
}).catch((error) => {
	console.log(':(', error);
})




